<template>
  <div class="adhocCharges">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">{{ title }}</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card :loading="loading" class="mb-4">
        <v-row>
          <v-col> </v-col>
          <v-col>
            <v-btn
              :loading="loadingData"
              :disabled="loadingData"
              color="teal"
              class="ml-1 mr-1 white--text"
              @click="loadConsumables"
            >
              Add/Edit Consumables
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              :disabled="loadingData"
              :loading="loadingData"
              color="light-blue darken-2"
              class="ml-1 mr-1 white--text"
              @click="loadStorageSizes"
            >
              Add/Edit Storage Sizes
            </v-btn>
          </v-col>
          <v-col> </v-col>
        </v-row>
      </v-card>
      <!-- CONSUMABLE MENU  -->
      <v-card v-if="consumablesMenu" class="mb-4 pa-6">
        <h1>Create Consumable</h1>
        <v-row>
          <v-col cols="3"> </v-col>
          <v-col cols="3">
            <v-text-field
              label="Consumable"
              v-model="newConsumableField"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-btn
              :disabled="loadingData"
              :loading="loadingData"
              color="primary"
              class="ml-1 mr-1"
              @click="saveConsumable()"
            >
              Create
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-card v-if="consumables.length > 0 && consumablesMenu" class="pa-6">
        <h1>Edit Current Consumables</h1>
        <v-divider></v-divider>
        <v-row class="pa-4">
          <template>
            <v-col
              v-for="consumable in consumables"
              cols="6"
              :key="consumable.snap_lookup_id"
            >
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="Consumable"
                    :id="String(consumable.snap_lookup_id)"
                    v-model="consumable.snap_lookup_text"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    :disabled="loadingData"
                    :loading="loadingData"
                    color="primary"
                    class="ml-1 mr-1"
                    @click="saveConsumable(consumable.snap_lookup_id)"
                  >
                    Save
                  </v-btn>
                </v-col>
                <v-col cols="3">
                  <v-btn
                    :disabled="loadingData"
                    :loading="loadingData"
                    color="red darken-3"
                    class="ml-1 mr-1 white--text"
                    @click="deleteConsumable(consumable.snap_lookup_id)"
                  >
                    Delete
                  </v-btn>
                </v-col>
              </v-row>
             
            </v-col>
          </template>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="5">
          </v-col>
          <v-col cols="2">
            <v-btn
                    :disabled="loadingData"
                    :loading="loadingData"
                    color="indigo lighten-1"
                    class="ml-1 mr-1 white--text"
                    @click="loadConsumables()"
                  >
                    Reset Changes
                  </v-btn>
          </v-col>
        </v-row>
      </v-card>

      <!-- STORAGE SIZES MENU -->
      <v-card v-if="storageSizesMenu" class="mb-4 pa-6">
        <h1>Create Storage Size</h1>
        <v-row>
          <v-col cols="1"> </v-col>
          <v-col cols="3">
            <v-text-field
              label="Storage Size"
              v-model="newStorageSizeField"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-select
              label="Default Tariff"
              :items="storageTariffs"
              item-text="tariff_type"
              item-value="tariff_id"
              v-model="newStorageSizeTariff"
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col cols="2">
            <v-checkbox
              class="mt-0"
              v-model="newStorageSizePerPallet"
              label="Per Pallet ID"
            >
            </v-checkbox>
          </v-col>
          <v-col cols="3">
            <v-btn
              :disabled="loadingData"
              :loading="loadingData"
              color="primary"
              class="ml-1 mr-1"
              @click="saveStorageSize()"
            >
              Create
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-card v-if="storageSizes.length > 0 && storageSizesMenu" class="pa-6">
        <h1>Edit Current Storage Sizes</h1>
        <v-divider></v-divider>
        <v-row
          v-for="storageSize in storageSizes"
          :key="storageSize.storage_id"
          class="pl-10"
        >
         
          <v-col cols="3">
            <v-text-field
              label="Storage Size"
              v-model="storageSize.storage_size"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-select
              label="Default Tariff"
              :items="storageTariffs"
              item-text="tariff_type"
              item-value="tariff_id"
              v-model="storageSize.default_tariff_id"
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col cols="2">
            <v-checkbox
              class="mt-0"
              v-model="storageSize.per_pallet_id"
              label="Per Pallet ID"
            >
            </v-checkbox>
          </v-col>

          <v-col cols="1">
            <v-btn
              :disabled="loadingData"
              :loading="loadingData"
              color="primary"
              class="ml-1 mr-1"
              @click="saveStorageSize(storageSize.storage_id)"
            >
              Save
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn
              :disabled="loadingData"
              :loading="loadingData"
              color="red darken-3"
              class="ml-1 mr-1 white--text"
              @click="deleteStorageSize(storageSize.storage_id)"
            >
              Delete
            </v-btn>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="5">
          </v-col>
          <v-col cols="2">
            <v-btn
                    :disabled="loadingData"
                    :loading="loadingData"
                    color="indigo lighten-1"
                    class="ml-1 mr-1 white--text"
                    @click="loadStorageSizes()"
                  >
                    Reset Changes
                  </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <v-snackbar v-model="snackbar" :timeout="4000">
      {{ text }}
      <v-btn color="blue" text @click="snackbar = false"></v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import BillingSnap from "@/services/BillingSnap.js";

export default {
  data() {
    return {
      validForm: true,
      // Title
      title: "Add/Edit Rates",
      // Snackbar
      snackbar: false,
      text: "",
      requiredRule: [(v) => !!v || "This field is required"],
      loadingData: false,
      consumables: [],
      storageSizes: [],
      consumablesMenu: false,
      storageSizesMenu: false,
      newConsumableField: "",
      newStorageSizeField: "",
      newStorageSizeTariff: {},
      newStorageSizePerPallet: false,
      storageTariffs: [],
    };
  },
  methods: {
    loadConsumables() {
      this.storageSizesMenu = false;
      this.consumablesMenu = true;
      BillingSnap.getConsumables().then((results) => {
        this.consumables = results;
      });
    },
    saveConsumable(id) {
      let tempForm = [];
      if (id) {
        //process already existent consumable
        let tempConsumable = this.consumables.find(
          (consumable) => consumable.snap_lookup_id === id
        );
        if (tempConsumable) {
          tempForm.push(tempConsumable);
        } else {
          this.snackbar = true;
          this.text = "Failed to find consumable with id " + id;
          return;
        }
      } else {
        //process new consumable
        if (this.newConsumableField) {
          let tempConsumable = { snap_lookup_text: this.newConsumableField };
          tempForm.push(tempConsumable);
        } else {
          this.snackbar = true;
          this.text = "Please Give The New Storage Size a Name";
          return;
        }
      }
      BillingSnap.saveConsumables(tempForm)
        .then((response) => {
          if (response.success == 200) {
            this.snackbar = true;
            this.text = response.data;
            this.newConsumableField = "";
            this.loadConsumables();
            return;
          }
        })
        .catch(() => {
          this.snackbar = true;
          this.text = "Failed To Save Consumable";
          return;
        });
    },
    deleteConsumable(id) {
      if (id) {
        let tempConsumable = this.consumables.find(
          (consumable) => consumable.snap_lookup_id === id
        );
        if (
          confirm(
            "Are you sure you want to delete consumable " +
              tempConsumable.snap_lookup_text
          )
        )
          BillingSnap.deleteConsumable({ snap_lookup_id: id })
            .then((response) => {
              if (response.success == 200) {
                this.snackbar = true;
                this.text = response.data;
                this.loadConsumables();
                return;
              }
            })
            .catch(() => {
              this.snackbar = true;
              this.text = "Failed To Delete Consumable With ID " + id;

              return;
            });
      } else {
        this.snackbar = true;
        this.text = "Failed To Get ID ";
        return;
      }
    },
    loadStorageSizes() {
      this.storageSizesMenu = true;
      this.consumablesMenu = false;
      BillingSnap.getStorageSizes().then((results) => {
        this.storageSizes = results;
      });
      BillingSnap.getStorageTariffs().then((tariffs) => {
        this.storageTariffs = tariffs;
      });
    },
    saveStorageSize(id) {
      let formData = [];
      if (id) {
        let tempStorageSize = this.storageSizes.find(
          (storageSize) => storageSize.storage_id === id
        );
        if (tempStorageSize) {
          formData.push(tempStorageSize);
        } else {
          this.snackbar = true;
          this.text = "Failed to find Storage Size with storage_id " + id;
          return;
        }
      } else {
        if (!this.newStorageSizeField) {
          this.snackbar = true;
          this.text =
            "Please Fill the Storage Size Field to create a new Storage Size";
          return;
        }
        if (!parseInt(this.newStorageSizeTariff)) {
          this.snackbar = true;
          this.text =
            "Please Select a default tariff to create a new Storage Size";
          return;
        }
        let tempStorageSize = {
          storage_size: this.newStorageSizeField,
          default_tariff_id: parseInt(this.newStorageSizeTariff),
          per_pallet_id: this.newStorageSizePerPallet ? 1 : 0,
        };
        formData.push(tempStorageSize);
      }
      if (formData.length > 0) {
        BillingSnap.saveStorageSizes(formData).then((response) => {
          if (response.success == 200) {
            this.snackbar = true;
            this.text = response.data;
            this.newStorageSizeField = "";
            this.newStorageSizeTariff = {};
            this.newStorageSizePerPallet = false;
            this.loadStorageSizes();
            return;
          }
        });
      }
    },
    deleteStorageSize(id) {
      if (id) {
        let tempStorageSize = this.storageSizes.find(
          (storageSize) => storageSize.storage_id === id
        );
        if (
          confirm(
            "Are you sure you want to delete Storage Size " +
              tempStorageSize.storage_size
          )
        )
          BillingSnap.deleteStorageSize({ storage_id: id })
            .then((response) => {
              if (response.success == 200) {
                this.snackbar = true;
                this.text = response.data;
                this.loadStorageSizes();
                return;
              }
            })
            .catch(() => {
              this.snackbar = true;
              this.text = "Failed To Delete Storage Size With storage_id " + id;

              return;
            });
      } else {
        this.snackbar = true;
        this.text = "Failed To Get ID ";
        return;
      }
    },
  },

  computed: {
    loading: function () {
      return this.$root.sharedData.state.loading;
    },
  },
};
</script>

<style>
h1 {
  font-size: 150% !important;
}
</style>